<template>
  <div class="page">
    <div v-if="loaded" class="back">
      <van-loading
        size="80px"
        text-size="24px"
        color="#fff"
        type="circular"
        vertical
        >加载中...</van-loading
      >
    </div>
    <div class="section-search">
      <div class="search-flex">
        <!-- <div class="flex-left">
          <img @click="back" src="./img/clear.png" alt="" />
        </div> -->
        <div class="flex-right">
          <div class="right-input">
            <div class="input-wrap">
              <div class="input-current">
                <div class="circle"></div>
                <v-input
                  ref="curInput"
                  v-model="form.curStart"
                  :maxlength="20"
                  placeholder="请选择当前位置"
                  @focus="curFocus"
                  @blur="curBlur"
                  @change="curChange"
                />
              </div>
              <div class="line"></div>
              <div class="input-arrive">
                <div class="circle"></div>
                <v-input
                  ref="arriveInput"
                  v-model="form.arrowEnd"
                  :maxlength="20"
                  placeholder="请选择目标位置"
                  @focus="arriveFocus"
                  @blur="arriveBlur"
                  @change="arriveChange"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="search-options" v-show="isList">
        <v-list
          :finished="finished"
          @onLoad="onLoad"
          ref="list"
          :finishedText="finishedText"
        >
          <div
            class="search-options--option"
            v-for="(option, oIndex) in listData"
            :key="oIndex"
            @click="chooseOption(option)"
          >
            <div class="image"><img src="./img/search_icon.png" alt="" /></div>
            <span>{{ option.name }}</span>
          </div>
        </v-list>
      </div>
    </div>
    <div class="section-iframe">
      <iframe
        id="planningIframe"
        class="bgIframe"
        :src="srcUrl"
        frameborder="0"
        width="100%"
        height="100%"
      ></iframe>
    </div>

    <div class="section-footer" v-if="planList.length > 0">
      <div class="footer-flex">
        <div class="warpper-flex">
          <div class="warpper-tip">
            <div class="warpper-tip--flex">
              <!-- <img src="./img/left_arrow_icon.png" alt="" /> -->
              <span>路线规划</span>
            </div>
          </div>
          <div class="warpper-content">
            <div
              :class="[
                'section-list',
                current.id === item.id && current.desc === item.desc
                  ? 'current'
                  : '',
              ]"
              v-for="(item, index) in planList"
              :key="index"
              @click="choosePlan(item)"
            >
              <div class="list-tip"><span>规划中</span></div>
              <div class="list-address">
                <span>{{ item.desc }}</span>
              </div>
              <!-- <div class="list-distance">{{ "--" }} 米</div> -->
              <div class="list-distance">第 {{ index + 1 }} 步</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import _ from "lodash";
import { Loading } from "vant";
import { mapState } from "vuex";
import { stepURL, bimRoadUrl } from "./api";
// import { handleImg } from "@/utils/utils";
export default {
  components: {
    vanLoading: Loading,
  },
  data() {
    return {
      srcUrl:
        "https://cgzc-dev.zhuneng.cn/d3mdoc/#/iframe/maze?id=14ea426f0f98421cab4f9ec01160c0d2",
      // isOption: false, // 是点击选项
      isShowIcon: false,
      isList: false,
      listData: [],
      planList: [],
      focus: undefined, // 1 前往 2 目的地
      curTab: {},
      curForm: {
        start: undefined, // 开始
        curStart: "",
        end: undefined, // 前往
        arrowEnd: "",
      },
      form: {
        start: undefined, // 开始
        curStart: "",
        end: undefined, // 前往
        arrowEnd: "",
        id: "14ea426f0f98421cab4f9ec01160c0d2",
      },
      timer: null,
      finished: true, // 列表是否记载完成
      finishedText: "没有更多了", // 列表加载完成后的提示语
      curPage: 1,
      pageSize: 20,
      start: "",
      current: {},
      loaded: true,
    };
  },
  watch: {},
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  async created() {
    window.addEventListener("message", this.changeLoaded);
    let { start } = this.$route.query;
    this.start = start;
    if (this.start) {
      await this.initStart();
    }
    this.$nextTick(() => {
      this.showIframe();
    });
  },
  beforeDestroy() {
    window.removeEventListener("message", this.changeLoaded);
  },

  methods: {
    async initStart() {
      let res = await this.$axios.get(bimRoadUrl, {
        params: {
          start: this.start,
        },
      });
      if (
        res.code == 200 &&
        res.data &&
        res.data.records &&
        res.data.records.length > 0
      ) {
        // this.listData = res.data.records;
        const option = res.data.records[0];
        this.form.curStart = option.name;
        this.form.start = option.id;
      }
    },
    // back() {},
    //防抖
    debounce(fn, wait) {
      if (this.timer !== null) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(fn, wait);
    },
    // 当前位置-聚焦触发
    curFocus() {
      this.focus = 1;
      this.isList = true;
      this.onRefresh();
    },
    // 当前位置-失焦触发
    curBlur() {},
    // 当前位置-数据变化
    curChange() {
      this.debounce(this.onRefresh, 500);
    },
    arriveFocus() {
      if (!this.form.start) {
        this.$toast({
          duration: 1000,
          message: "请选择起始位置",
        });
        return;
      }
      this.focus = 2;
      this.isList = true;
      this.onRefresh();
    },
    arriveBlur() {},
    arriveChange() {
      this.debounce(this.onRefresh, 500);
    },
    changeLoaded(ev) {
      // 监听iframe资源是否加载完成，可以发送postmessage
      if (ev.data && ev.data.type === "loaded") {
        this.loaded = false;
        //进行操作
      }
    },
    chooseOption(item) {
      this.isList = false;
      if (this.focus == 1) {
        this.form.curStart = item.name;
        this.form.start = item.id;
      } else if (this.focus == 2) {
        if (!this.form.start) {
          this.$toast({
            duration: 1000,
            message: "请选择起始位置",
          });
          return;
        }
        this.form.arrowEnd = item.name;
        this.form.end = item.id;
        // this.showIframe();
        this.getPlanList();
      }
    },
    showIframe(current) {
      let iframe = document.getElementById("planningIframe").contentWindow;
      const data = {
        start: this.form.start,
        end: this.form.end,
        id: this.form.id,
      };
      iframe.postMessage(
        // Object,start和end为POI的id，id为导航BIM的资源id
        { type: "maze", data: current || data },
        "*"
      );
    },

    choosePlan(item) {
      if (item.guide) {
        this.current = item;
        this.showIframe(this.current);
      }
    },

    // 加载列表数据
    getPlanList() {
      this.planList = [];
      this.$axios
        .get(stepURL, {
          params: {
            start: this.form.start, // 开始
            end: this.form.end, // 前往
            // priorityFloor: this.curTab.value, // 地下,  地上
            priorityFloor: -1,
          },
        })
        .then((res) => {
          if (res.code == 0 && res.data) {
            this.planList = res.data;
            this.current = this.planList.filter((v) => v.guide)[0];
            let iframe =
              document.getElementById("planningIframe").contentWindow;
            if (iframe && this.current.guide) {
              const _this = this;
              this.showIframe(this.current);
            }
          }
        });
    },

    onRefresh() {
      this.finishedText = "";
      let name = "";
      /* 请求接口前清空当前、到达的id */
      if (this.focus == 1) {
        name = this.form.curStart;
      } else if (this.focus == 2) {
        name = this.form.arrowEnd;
      }
      /* end */
      // this.finished = true;
      this.curPage = 1;
      this.listData = [];
      this.onLoad(name);
    },
    // 加载列表数据
    onLoad(name) {
      this.$axios
        .get(bimRoadUrl, {
          params: {
            name,
            curPage: this.curPage,
            pageSize: this.pageSize,
            // start: this.form.start, // 开始
            // end: this.form.end, // 前往
            // priorityFloor: this.curTab.value, // 地下,  地上
          },
        })
        .then((res) => {
          if (res.code != 200) {
            this.finished = true;
          } else {
            if (this.curPage >= res.data.pages) {
              this.finished = true;
            } else {
              this.finished = false;
              this.$refs.list.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              this.listData = [];
              return;
            }
            if (res.code === 200 && res.data && res.data.records) {
              if (this.curPage === 1) {
                this.listData = res.data.records;
              } else {
                const list = this.listData.map((v) => v.id);
                res.data.records.forEach((item) => {
                  // 如果请求数据不重复，则添加进listData
                  list.includes(item.id) || this.listData.push(item);
                });
              }
              this.curPage++;
            }
          }
        })
        .catch(() => {
          this.finished = true;
        })
        .finally(() => {
          // this.isLoading = false;
        });
    },
  },
};
</script>
<style lang="less" scoped>
.page {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  z-index: 9998;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  background: url("./img/background.png") no-repeat left top;
  background-size: 100% 484px;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */

  .back {
    left: 0;
    top: 0;
    z-index: 9999;
    position: absolute;
    width: 100%;
    height: 100%;
    background: url("./img/back.png") center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .section-search {
    box-sizing: border-box;
    z-index: 9998;
    width: 100%;
    //  height: 484px;
    padding: 80px 30px 64px;
    position: absolute;
    left: 0;
    top: 0;
    .search-flex {
      display: flex;
      width: 100%;
      box-sizing: border-box;
      padding: 30px;
      background: #ffffff;
      border-radius: 16px;
      box-shadow: 0px 4px 22px 0px rgba(229, 229, 229, 0.5);
      .flex-left {
        margin-right: 48px;
        img {
          // height: 26px;
          // width: 14px;
          height: 24px;
          width: 24px;
        }
      }
      .flex-right {
        flex: 1;
        .right-input {
          display: flex;
          align-items: center;
          background: #f9f9f9;
          width: 100%;
          height: 160px;
          border-radius: 16px;
          //  font-size: 28px;
          .input-wrap {
            display: flex;
            flex-direction: column;
            height: 100%;
            .input-current,
            .input-arrive {
              flex: 1;
              display: flex;
              align-items: center;
              margin-right: 64px;
              .circle {
                margin: 0 12px 0 28px;
                display: flex;
                justify-content: center;
                align-items: center;
                &::before {
                  content: "";
                  width: 12px;
                  height: 12px;
                  background: #fff;
                  border-radius: 50%;
                  border: 6px solid #68cfa8;
                }
              }
              ::v-deep .van-cell {
                width: 356px;
                background-color: transparent;
                line-height: 40px;
                padding: 0;
                font-size: 28px;
                color: rgba(0, 0, 0, 0.85);
              }
            }
            .line {
              margin: 0 18px 0 50px;
              width: 420px;
              height: 2px;
              background: #f1f1f1;
            }
            .input-arrive {
              .circle {
                &::before {
                  border: 6px solid #fa6400;
                }
              }
            }
          }
          .input-icon {
            img {
              width: 64px;
              height: 64px;
              vertical-align: middle;
            }
          }
        }
        .right-tabs {
          display: flex;
          margin-top: 22px;
          font-size: 26px;
          font-weight: 500;
          .right-tabs--tab {
            border-radius: 10px;
            padding: 8px 14px;
            margin-right: 36px;
            color: rgba(0, 0, 0, 0.5);
            &.selectTab {
              background: linear-gradient(180deg, #549afe, #247dff);
              color: #ffffff;
            }
          }
        }
      }
    }
    .search-options {
      position: absolute;
      top: 320px;
      z-index: 999;
      overflow-y: auto;
      box-sizing: border-box;
      // margin-top: 20px;
      background: #ffffff;
      box-shadow: 0px 4px 22px 0px rgba(229, 229, 229, 0.5);
      padding: 0px 22px 22px;
      font-size: 28px;
      color: rgba(0, 0, 0, 0.85);
      width: calc(100% - 60px);
      height: calc(100vh - 380px);
      .search-options--option {
        display: flex;
        padding: 30px 12px;
        border-bottom: 2px solid #eeeeee;
        line-height: 40px;
        .image {
          padding-top: 4px;
          img {
            width: 28px;
            height: 28x;
            margin: 0 18px 0 0;
          }
        }
        span {
          word-wrap: break-word;
          word-break: break-all;
        }
      }
    }
  }
  .section-iframe {
    flex: 1;
    .bgIframe {
      border: 0;
      vertical-align: top;
    }
  }
  .section-footer {
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 2;
    width: 100%;
    height: 160px;
    background: #ffffff;
    padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
    padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
    .footer-flex {
      position: relative;
      height: 100%;
      margin: 0 30px;
      background: #ffffff;
      .warpper-flex {
        position: absolute;
        bottom: 20px;
        left: 0;
        width: 100%;
        .warpper-tip {
          display: inline-block;
          box-shadow: 0px 4px 22px 0px #e5e5e5;
          border-radius: 20px;
          font-size: 32px;
          line-height: 45px;
          padding: 20px 36px;
          margin-bottom: 20px;
          background: #ffffff;
          .warpper-tip--flex {
            display: flex;
            align-items: center;
            img {
              height: 26px;
              width: 14px;
              margin-right: 20px;
              vertical-align: middle;
            }
            span {
              vertical-align: middle;
            }
          }
        }
        .warpper-content {
          width: 100%;
          min-height: 230px;
          background: #f9f9f9;
          display: flex;
          border-radius: 20px;
          overflow-x: auto;
          overflow-y: hidden;
          box-shadow: 0px 4px 22px 0px rgba(229, 229, 229, 0.5);
          .section-list {
            box-sizing: border-box;
            width: 32%;
            min-width: 32%;
            color: rgba(229, 229, 229, 0.5);
            padding: 22px 26px;
            border-radius: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: rgba(0, 0, 0, 0.85);
            &:not(:first-child) {
              margin-left: 2%;
            }
            .list-tip {
              opacity: 0;
              height: 34px;
              font-size: 24px;
            }
            .list-address {
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
              font-weight: 600;
              font-size: 26px;
              // margin: 20px 0;
            }
            .list-distance {
              font-size: 24px;
            }
            &.current {
              color: #237dff;
              background: #fff;
              box-shadow: 0px 4px 22px 0px rgba(229, 229, 229, 0.5);
              .list-tip {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}
</style>
